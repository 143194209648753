@import './breakpoints.scss';

.form-group label.required::after {
  content: ' *';
  color: red;
}

.card.equal {
  height: calc(100% - 1.5rem);
}

.btn-table {
  padding: 0px !important;
  font-size: 1rem;
  outline: 0;
}

.btn-table:focus {
  box-shadow: none !important;
  outline: 0;
}

.btn-table:hover {
  color: $primary !important;
}

.center-flex {
  display: flex;
  align-items: center;
}

.card-header-actions-flex {
  margin-left: auto;
}

.block {
  display: block !important;
}

.card-header-actions-flex .select-with-datepicker .form-group {
  margin-bottom: 0;
  margin-right: 12px;
  margin-top: 0.5px;
  width: 251.45px;
}

.rapor-select .form-group {
  width: 245px;
}

.limit-height.modal-body {
  overflow-y: scroll;
  max-height: 70vh;
}

.bg-tikir-cyan {
  background-color: #00D1CA;
}

.bg-tikir-purple {
  background-color: #CD46FF;
}

@include media("<=LGphone") {
  .center-flex {
    flex-wrap: wrap;
  }

  .card-header-actions-flex {
    padding: .75rem 1rem;

    .form-group {
      margin-bottom: 6px;
    }
  }
}