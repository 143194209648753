// Variable overrides

// Default
$primary: #8014fd;
$secondary: #ced2d8;
$success: #2eb85c;
$info: #39f;
$warning: #f9b115;
$danger: #e55353;
$light: #ebedef;
$dark: #636f83;